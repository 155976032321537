<template>
    <div class="blogItem__container">
        <div class="blogItem__title">{{ title }}</div>
        <div class="blogItem__intro">{{ intro }}</div>
        <div class="bottomCont">
            <div class="bottomCont__tagsCont">
                <div class="bottomCont__tag" v-for="tag in tagsArray" :key="tag">{{ tag }}</div>
            </div>
            <a :href="url" class="bottomCont__LinkCont">READ MORE <img src="../assets/images/blog-arrow.svg" alt=""></a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        intro: String,
        url: String,
        tags: String
    },
    computed:{
        tagsArray(){
            if(this.tags) return this.tags.split(",")
            else return ""
        }
    }
}
</script>

<style lang="scss" scoped>
.blogItem {
    &__container {
        border-bottom: 1px solid black;
        margin-bottom: 24px;
        padding-block: 32px;
        margin-right: 12px;
        @media screen and (max-width:768px) {
            margin-right: 0;
        }
    }

    &__title {
        font-family: Roobert, Arial, Helvetica, sans-serif;
        font-size: 26px;
        line-height: 112%;
        margin-bottom: 24px;
        @media screen and (max-width:768px) {
            padding: 0 20px;
            font-size: 22px;
        }
    }

    &__intro {
        font-family: Roslindale, serif;
        font-weight: normal;
        font-variation-settings: "wght" 320, "wdth" 75;
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 60px;
        @media screen and (max-width:768px) {
            padding: 0 20px;
            font-size: 20px;
        }
        &::after{
            content: '';
        }
    }
}

.bottomCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width:768px) {
            padding: 0 20px;
        }
    

    &__tagsCont {
        display: flex;
        justify-content: flex-start;
        gap: 16px;
        @media screen and (max-width:768px) {
            gap: 8px;
            flex-wrap: wrap;
        }

    }

    &__tag {
        font-family: Roobert, Arial, Helvetica, sans-serif;
        border: 1px solid black;
        text-transform: uppercase;
        padding: 3px 10px;
        border-radius: 40px;
        font-size: 14px;
    }

    a{
        font-family: Roobert, Arial, Helvetica, sans-serif;
        font-size: 14;
        display: flex;
        gap: 16px;
        align-items: center;
        cursor: pointer;
        padding-right: 20px;
        @media screen and (max-width:768px) {
            padding-right: 0;
            width: max-content;
            
        }
        
        img{
            height: 35px;
            width: auto;
            transition: 200ms ease-out;
            @media screen and (max-width:768px) {
            height: 20px;
        }
        }
        @media (hover: hover){
        &:hover{
            text-decoration: underline;
            img{
                transform: translateX(8px);
            }
        }
    }

        }
    }
</style>