import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firestoreAction } from "vuexfire"
import {db} from "../firebase"


Vue.use(Vuex);

const customSerializer = (doc) => {
  const data = doc.data()
  // adds _doc to be used to paginate
  Object.defineProperty(data, '_doc', { value: doc })
  // adds id as enumerable attribute so we can easily access it
  Object.defineProperty(data, 'id', { value: doc.id, enumerable: true })
  return data
}

export default new Vuex.Store({
  state: {
    titlePrj: 'LA SCIENZA DELLE SOLUZIONI IMMAGINARIE',
    imgPrj: '',
    homeContent: {"home":{},"about":{},"blog":{},"contacts":{}},
    prj:"",
    loading: false,
    prjbuss: "",
    prjn03: "",
    who: false,
    what: false,
    enterAnim: true,
    claps:[]
  },
  getters: {
    claps: (state) => {
      return state.claps
    },
  },
  mutations: {
    pushTitle(state, title){
      state.titlePrj = title || "LA SCIENZA DELLE SOLUZIONI IMMAGINARIE"
    },
    pushImg(state, img){
      state.imgPrj = img
    },
    pushHome(state, home){
      for(var i of home){
        if(i.type === "home") state.homeContent.home = i;
        else if(i.type === "about") state.homeContent.about = i;
        else if(i.type === "contacts") state.homeContent.contacts = i;
        else if(i.type === "blog") state.homeContent.blog = i;
      }
    },
    pushPrj(state, prj){
      state.prj = prj 
      var bus = [];
      var enne = [];
      function compare( a, b ) {
        if ( a.data.order < b.data.order ){
          return -1;
        }
        if ( a.data.order > b.data.order ){
          return 1;
        }
        return 0;
      }

      prj.forEach(p => {
        p.data.order ??= 666
        p.data.n_03 ? enne.push(p) : bus.push(p)
      });

      enne.sort(compare)
      bus.sort(compare)

      state.prjbuss = bus
      state.prjn03 = enne

    },
    removeImg(state){
      state.imgPrj = ''
    },
    loadedApi(state){
      state.loading = true
    },
    closeMM(state, menu){
      menu === "who" ? state.who = false : state.what = false
    },
    openMM(state, menu){
      menu === "who" ? state.who = true : state.what = true
    },
    trigAnim(state){
      state.enterAnim = false
    },
    ...vuexfireMutations,
  },
  actions: {
    bindClap: firestoreAction(({ bindFirestoreRef },) => {
      return bindFirestoreRef("claps", db.collection("claps"), { serialize: customSerializer });
    }),
    addClap: firestoreAction((context, payload) => {
      return db.collection('claps').add(payload.newBook)
    }),
    updateClap: firestoreAction(( context , payload ) => {
      console.log(payload.bookId)
      db.collection('claps')
        .doc(context.state.claps[payload.bookId].id)
        .update({ clap: context.state.claps[payload.bookId].clap + 1 })
    }),
  },
  modules: {},
});
