import { preloadImages } from './utils';
import { Grid } from './grid';
// import store from '@/store';

// Preload images then remove loader (loading class) from body
// preloadImages('.column__item-img').then(() => {
//     document.body.classList.remove('loading');
//     console.log("CACCA")
//     // Initialize the grid
//     new Grid(document.querySelector('.columns'));
// });

export default{
    data() {
        return {
        test: ""
        }
    },
    methods: {
        prelod(){
        preloadImages('.column__item-img').then(() => {
            document.body.classList.remove('loading');
            // Initialize the grid
            this.test = new Grid(document.querySelector('.columns'));
            this.$store.commit("trigAnim");
            
            // console.log(test)
        })
    },
    destroy(){
        this.test.lscroll.destroy()
    }
    },
    
}