<template>
    <!-- <div class="logo__container">
        <img src="../assets/images/thebuss-logo-w.svg" alt="thebuss logo" class="logo">
    </div> -->
    <figure class="column__item">
        <div class="column__item-imgwrap mask" :data-pos="pos"
            :style="`mask-image: url('/assets/images/${shape}.svg');`">
            <router-link :to="`/${url}`">
                <div class="column__item-img" @mouseenter="changeTitle(title)" @mouseleave="changeTitle('')"
                    :style="`background-image: url(${$store.state.imgPrj || selectImg})`"></div>
            </router-link>
        </div>
        <!-- <figcaption class="column__item-caption">
            <span>Gnostic Will</span>
            <span>2012</span>
        </figcaption> -->
    </figure>
</template>

<script>

export default {
    props: {
        pos: Number,
        // img: Number,
        // shape: String
    },
    data() {
        return {
            realNum: 0
        }
    },
    computed: {
        // Num(){
        //     var n = this.pos
        // while(this.$store.homeContent.data.work.length - 1 > n){
        //     n = n - this.$store.homeContent.data.work.length - 1
        // } 
        //     return
        // },
        selectImg() {
            return this.$store.state.homeContent.home.data.work[this.realNum].gif.url
        },
        title() {
            const objPr = this.$store.state.prj
           const key = this.$store.state.homeContent.home.data.work[this.realNum].prj.uid
            var result = objPr.find(obj => {
                return obj.uid === key
            })
            return result.data.title
        },
        url() {
            return this.$store.state.homeContent.home.data.work[this.realNum].prj.uid
        },
        shape() {
            return this.$store.state.homeContent.home.data.work[this.realNum].shape.toLowerCase();
        }
    },
    methods: {
        changeTitle(text) {
            this.$store.commit("pushTitle", text)
            if (text) {
                this.$store.commit("pushImg", this.selectImg)
                // this.$store.commit("spreadImg")
            } else {
                this.$store.commit("removeImg")
            }
        }
    },
    mounted() {
        //    console.log() 
        if (this.$store.state.homeContent.home.data.work.length - 1 > this.pos) this.realNum = this.pos
        else {
            var m = this.pos
            while (this.$store.state.homeContent.home.data.work.length <= m) {
                m = m - this.$store.state.homeContent.home.data.work.length
            }
            this.realNum = m
        }

    },

}
</script>

<style lang="scss" scoped>
.column__item-img {
    // pointer-events: none;
    width: 100%;
    height: 100%;
}

.mask {
    // margin: 0;
    // -webkit-mask-image: url("../assets/images/mask/arc.svg");
    // mask-image: url("../assets/images/mask/arc.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    user-select: none;
    pointer-events: none;
    overflow: hidden;
    // pointer-events: none;
    a{
        pointer-events:visible;
    }
}
</style>