<template>
  <div id="app">
    <SideMenu v-if="loaded" :left="true" firstTog="WORKS" secondTog="BLOG" class="desk" v-responsive="['hidden-all','lg', 'xl']"/>
    <SideMenu v-if="loaded" :left="false" firstTog="ABOUT" secondTog="CONTACTS" class="desk" v-responsive="['hidden-all','lg', 'xl']" />
    <MobileTopBar v-if="loaded" v-responsive.md.sm.xs />
    <SideMenuMob firstTog="WORKS" secondTog="BLOG" :left="true" v-if="$store.state.what"/>
    <SideMenuMob firstTog="ABOUT" secondTog="CONTACT" :left="false" v-if="$store.state.who"/>
    <router-view v-if="loaded"/>
  </div>
</template>

<script>
import SideMenu from './components/sideMenu.vue';
import MobileTopBar from './components/MobileTopBar.vue';
import SideMenuMob from './components/sideMenuMob.vue';
// import {fireapp} from "./firebase"
// import { db } from './firebase';

export default {
  data() {
    return {
      response: "",
      prj: "",
      zada: [],
      documents: [],
    }
  },
  components: { SideMenu, MobileTopBar, SideMenuMob },
  // firestore: {
  //   documents: db.collection('documents'),
  // },
  methods: {
    async getHome() {
      // Query the API and assign the response to "response"
      const response = await this.$prismic.client.query(
        this.$prismic.predicate.not('document.type', 'project')
      )
      this.$store.commit("pushHome", response.results)
      this.response = response
      // console.log(this.response)
      this.$store.commit('loadedApi')
    },
    async getPrj() {
      // Query the API and assign the response to "response"
      const response = await this.$prismic.client.query(
        this.$prismic.predicate.at('document.type', 'project'),{
          pageSize: 50
        }
      )
      this.$store.commit("pushPrj", response.results)
      this.prj = response
      // console.log(this.prj)
    },
    // getDataDB() {
    //   var test = firebase.database().ref("/");
    //   test.once("value").then((snapshot) => {
    //     snapshot.forEach((child) => {
    //       let k = child.key;
    //       this.zada.push(k);
    //       console.log();
    //     });
    //   });
    //   // this.$store.commit("setFbDatabase", this.zada);
    //   console.log(this.zada);
    // },
  },

  computed:{
    loaded(){
      return this.$store.state.loading
    }
  },
  created() {
    // Call the API query method
    this.getPrj()
    this.getHome()
    this.$store.dispatch('bindClap')
    // this.getDataDB()
    // db.collection('students').get()
    //   .then(snapshot => {
    //     snapshot.forEach(doc => {
    //       let item = doc.data()
    //       item.id = doc.id
    //       this.documents.push(item)
    //     })
    //   })
  }
}
</script>

<style lang="scss">
html{
  scroll-behavior: smooth;
}
@font-face {
  font-family: "Roslindale";
  src: url("assets/fonts/RoslindaleVariable-VF-Display.woff2") format("woff2-variations"),
    url("assets/fonts/RoslindaleVariableDisplay-Regular.woff") format("woff"),
    url("assets/fonts/RoslindaleVariableDisplay-Regular.ttf") format("truetype");
  font-display: fallback;
  // font-weight: 850;
}

@font-face {
  font-family: "Roobert";
  src: url("assets/fonts/Roobert-Regular.woff2") format("woff2"),
    url("assets/fonts/Roobert-Regular.woff") format("woff"),
    url("assets/fonts/Roobert-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

.desk{
  @media screen and (max-width: 768px) {
    display: none;
  }
}

a, button{
  color: black;
}
</style>
