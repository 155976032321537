<template>
  <!-- <div class="home" :style="`$store.state.imgPrj ? 'background-image:url(/assets/images/${$store.state.imgPrj})' : ''`"> -->
  <div class="home">
    <transition name="slide-fade">
      <!-- <div class="enterText__container" v-if="show && first"> -->
      <div v-if="$store.state.enterAnim" class="enterMenu__container">
        <!-- <div v-if="enterAnim" class="enterMenu__container"> -->
        <TitleHome class="enterMenu__title" :enter="true" />
        <div class="enterMenu__menu enterMenu__menu--left"></div>
        <div class="enterMenu__menu enterMenu__menu--right"></div>
        <!-- <button @click="tog">Toogle</button> -->
      </div>
    </transition>
    <div class="background" :style="`background-image:url(${$store.state.imgPrj})`"></div>
    <MainLogo class="selector" />
    <!-- my custom pointer -->
    <main v-responsive.lg.xl>

      <div class="columns" data-scroll-container v-if="$store.state.loading">
        <TitleHome v-responsive="['hidden-all', 'lg', 'xl']" class="selector" />
        <div class="column-wrap column-wrap--height">
          <div class="column">
            <ItemGrid v-for="n in 8" :pos="2 + (n - 1) * 3" :key="'first_' + n" />
          </div>
          <!-- /column -->
        </div>
        <!-- /column-wrap -->
        <div class="column-wrap">
          <div class="column" data-scroll-section>
            <ItemGrid v-for="n in 8" :pos="1 + (n - 1) * 3" :img="10 - n" :key="'second_' + n" />
          </div>
          <!-- /column -->
        </div>
        <div class="column-wrap column-wrap--height">
          <div class="column">
            <ItemGrid v-for="n in 8" :pos="3 + (n - 1) * 3" :key="'third_' + n" />
          </div>
          <!-- /column -->
        </div>
        <!-- /column-wrap -->
      </div>
      <!-- columns -->

    </main>
    <div class="mobileHome__cont" v-responsive.sm.xs.md data-scroll-container="" v-if="$store.state.loading">
      <div class="arrow__container">
        <img src="@/assets/images/Arrow_home.svg" alt="">
      </div>
      <div class="mobileHome__parent">
        <TitleHome class="selector" />
        <div class="mobileHome__slide mobileHome__slide-default" id="-1">
          <div class="gridMob__container">
            <div class="gridMob__item" v-for="num in 9" :key="num">
              <item-grid-mobile :prj="$store.state.homeContent.home.data.work[num]" :intro="true" />
            </div>
          </div>
        </div>
        <div class="mobileHome__slide" v-for="(prj, index) in $store.state.homeContent.home.data.work" :key="index"
          :id="index">
          <item-grid-mobile :prj="prj" />
          <div class="mobileHome__background" :style="`background-image:url(${prj.gif.url})`"></div>

        </div>
      </div>
    </div>
  </div>

  <!-- scene 1 -->
  <!-- <section class="fv-100 scene1">
      <div ref="logo" class="logo">
        <figure class="image is-100x100">
          <img
            class="is-rounded"
            src="../assets/images/logo.png"
            alt="help me world"
          />
        </figure>
      </div>

      <div
        ref="mouseIcon"
        class="mouse-container pointer"
        @click="scrollMeTo('.scene2', 3)"
        @mouseenter="mousePointerEnter"
        @mouseleave="mousePointerLeave"
      >
        <div class="mouse-body">
          <div class="mouse-wheel"></div>
        </div>
      </div>
    </section> -->

  <!-- scene 2 -->
  <!-- <section
      class="fv-100 scene2 centerXY has-background-success has-text-white"
    >
      <div @mouseenter="mousePointerEnter" @mouseleave="mousePointerLeave">
        <h1 class="is-size-1 namaste">😌 namaste</h1>
      </div>
    </section> -->
  </div>
</template>

<script>
// mixins are sexy yeah!
// import { mousePointer } from "../mixins/mousePointer";
// import { homeTweens } from "../mixins/homeTweens";
import scrollLoc from "../mixins/js/index";
// import  fuck  from "../mixins/js/fuck";
import MainLogo from "@/components/MainLogo.vue";
import TitleHome from "@/components/titleHome.vue";
import ItemGrid from "../components/itemGrid.vue";
import ItemGridMobile from '@/components/itemGridMobile.vue';
import {db} from "../firebase"

export default {
  mixins: [scrollLoc],
  // mixins: [homeTweens, scrollLoc],
  name: "HomeView",
  components: { MainLogo, TitleHome, ItemGrid, ItemGridMobile },
  data() {
    return {
      observer: null,
      enterAnim: true,
      response: "",
      shape: [
        "arc", "bubble", "circle", "curve", "ellipse", "flag", "hourglass", "parallelepiped", "polygon"
      ],
      documents: [],
    }
  },
  firestore: {
    documents: db.collection('claps'),
  },
  computed: {
    lod() {
      return this.$store.state.loading
    }
  },
  watch: {
    pre() {
      // this.prelod()
    }
  },
  methods: {
    changeTitle(text) {
      this.$store.commit("pushTitle", text)
      // if (text) {
      //           this.$store.commit("pushImg", this.selectImg)
      //           // this.$store.commit("spreadImg")
      //       } else {
      //           this.$store.commit("removeImg")
      //       }
    },
    async getContent() {
      // Query the API and assign the response to "response"
      const response = await this.$prismic.client.query('')
      this.response = response
    },
    tog() {
      this.enterAnim = !this.enterAnim
    },
    observerProv() {
      // await this.$nextTick(() => {
      var callback = (entries) => {
        // console.log("Male")
        // entries.forEach((entry) => {
        //   // const ratio = entry.intersectionRatio;
        //   const element = entry.boundingClientRect;
        //   // const element = entry.target;
        //   // console.log(element);
        //   console.log(entry.isIntersecting)
        //   // console.log(element.right)
        //   // if(element.right < this.ImgMar) console.log(entry.target.id)
        //   if(element.right < this.ImgMar) console.log(entry.target.id)
        //   // if(entry.boundingClientRect.left < 20) this.$store.commit("forwardPrj", 1);
        // }
        // );
        // console.log(entries)
        entries.forEach((entry) => {
          // if(entry.intersectionRatio === 1)console.log(entry.target.id)
          if (entry.intersectionRatio === 1) {
            var id = parseInt(entry.target.id)
            if (id >= 0) {
              var key = this.$store.state.homeContent.home.data.work[id].prj.uid
              // var gif = this.$store.state.homeContent.home.data.work[id].gif.url
              var result = this.$store.state.prj.find(obj => {
                return obj.uid === key
              })

              // console.log(result.data.title);
              // console.log(key);
              // this.$store.commit("pushImg", gif)
              this.changeTitle(result.data.title)
            }
            else {
              this.$store.commit("removeImg")
              this.changeTitle()
            }
          }
        });

        // title() {
        //     const objPr = this.$store.state.prj
        //    const key = this.$store.state.homeContent.home.data.work[this.realNum].prj.uid
        //     var result = objPr.find(obj => {
        //         return obj.uid === key
        //     })
        //     return result.data.title

        // for(var i = 1; i < entries.length; i++){
        //   // console.log("test" + entries[0].target.id)
        //   console.log("suca " + i )
        //   if(entries[i].isIntersecting) console.log(entries[i].target.id)
        //   else console.log("nada")
        // }
      };
      var options = {
        root: document.querySelector(".mobileHome__parent"),
        threshold: [1],
      };
      var targets = document.querySelectorAll(".mobileHome__slide");
      var observer = new IntersectionObserver(callback, options);
      // console.log(targets)
      targets.forEach((target) => observer.observe(target));
      // });
    },

    // onElementObserved(entries) {
    //   entries.forEach(({ target, isIntersecting}) => {
    //     // do something ...
    //   });
    // }
  },
  mounted() {
    if (window.innerWidth > 768) {
      this.prelod()
    } else {
      this.$store.commit("trigAnim")
      setTimeout(this.observerProv, 500);
      //   this.observer = new IntersectionObserver(
      //   this.onElementObserved, 
      //   {
      //     root: this.$el,
      //     threshold: 1.0,
      //   }
      // );
    }
    this.enterAnim = false;
  },
  created() {
    // Call the API query method
    this.getContent()
  },
  beforeDestroy() {
    this.changeTitle("")
    this.$store.commit('removeImg')
    this.destroy()
  }
};
</script>

<style lang="scss" scoped>
.home {
  background: $light-color;
  background-size: cover;
  background-position: center;
  // filter: blur(5px);
  backdrop-filter: blur(2px);
}

.background {
  position: fixed;
  top: -10vh;
  left: 0;
  width: 100vw;
  height: 120vh;
  background-size: cover;
  background-position: center;
  filter: blur(10px);

  // backdrop-filter: blur(2px);
  @media screen and (max-width:768px) {
    height: 100vh;
  }
}

// .slide-fade-enter-active {
//   transition: all 3s ease;
// }
// .slide-fade-leave-active {
//   transition: all 5s ease-in;
//   transition-delay: 1s;
// }
// .slide-fade-enter, .slide-fade-leave-to
// /* .slide-fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }
// .slide-fade-leave{
//   opacity: ;
// }

// .slide-fade-leave-from {
//   opacity: 1;
// }

// .slide-fade-enter-active {
//   transition: all 3s ease;
// }

// .slide-fade-leave-to {
//   opacity: 0;
// }
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 3s ease;
  transition-delay: 2s;

  .enterMenu__menu--right,
  .enterMenu__menu--left,
  .enterMenu__title {
    transition: all 600ms ease-in;
    transition-delay: 2s;
  }
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  // opacity: 0;
  z-index: 999999;

  .enterMenu__menu--right {
    transform: translateX(50vw);
  }

  .enterMenu__menu--left {
    transform: translateX(-50vw);
  }

  .enterMenu__title {
    opacity: 0;
    filter: blur(40px);
  }

}

.slide-fade-enter-to,
.slide-fade-leave-from {
  opacity: 1;
  z-index: 999999;

  .enterMenu__menu--right {
    // transform: translateX(50vw);
  }
}


.enterMenu {
  &__container {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999999;

    button {
      position: fixed;
      top: 45px;
      left: 500px;
      z-index: 999999999;
    }
  }

  &__menu {

    @include main-bg-p3;
    position: fixed;
    top: 0;
    width: 50vw;
    height: 100vh;
    z-index: 99999;
    display: flex;

    // transition: 600ms ease-out;
    &--right {
      padding-right: 0px;
      right: 0;
      flex-direction: row-reverse;

      // transform: translateX(calc(50vw - 50px));
      &-leave-to {
        transform: translateX(50vw);
        background: brown;
      }

      &-leave-from {
        background: red;
      }
    }

    &--left {
      padding-left: 0px;
      left: 0 // transform: translateX(calc(-50vw + 50px));
    }
  }

}

.mobileHome {
  &__cont {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;
    // border: 4px solid lime;
    height: 100vh;
    // height: calc(100vh - 60px);
    background: white;
    // overflow: scroll;

    &::before {
      content: '';
      z-index: 800;
      pointer-events: none;
      background: linear-gradient(to bottom, var(--color-bg), 10%, $light-color);
      // background: linear-gradient(to bottom, red, blue);
      // background: blue;
      // position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      // height: calc(100vh - 60px);
      height: 100vh;


    }
  }

  &__parent {
    overflow: scroll;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;
    height: 100vh;
    background-color: white;

  }

  &__slide {
    position: relative;
    scroll-snap-align: start;
    height: 100vh;
    // background: linear-gradient(to bottom, purple, blue);
    color: white;
    //   background-size: cover;
    // background-position: center;
    // filter: blur(10px);
    background-color: white;
    overflow: hidden;
  }

  &:nth-child(odd) {
    background: aquamarine;
    color: white;
  }

  &__background {
    position: absolute;
    width: 110vw;
    height: 110vh;
    left: -5vw;
    top: -5vw;
    background-size: cover;
    background-position: center;
    filter: blur(10px);
    background-color: white;
  }
}

.selector {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none
}

.gridMob__container {
  position: absolute;
  left: -25vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 150vw;
  height: 100vh;
  grid-auto-flow: column;
}

.gridMob__item:nth-child(-n+3) {
  transform: translateY(50px);
  // background: red
}

.gridMob__item:nth-child(-n+6):nth-child(n+4) {
  // background: blue;
  transform: translateY(-50px);
}

.gridMob__item:nth-child(n+6) {
  // background: green;
  transform: translateY(50px);
}

.arrow__container{
  position: fixed;
  top: 80vh;
  // background: red;
  left: 0;
  width: 100%;
  height: 12vh;
  display: flex;
  justify-content: center;
  z-index: 999;
  -webkit-mix-blend-mode: exclusion;
    mix-blend-mode: exclusion;
    -moz-mix-blend-mode: exclusion;
    -o-mix-blend-mode: exclusion;
    -ms-mix-blend-mode: exclusion;

    &>img{
      animation: bounce 600ms ease-in-out infinite alternate;
    }

  }
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(30px);
    }
  }


</style>
