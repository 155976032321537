<template>
    <!-- <div class="logo__container">
        <img src="../assets/images/thebuss-logo-w.svg" alt="thebuss logo" class="logo">
    </div> -->
    <figure :class="intro ? 'column__item--intro' : 'column__item--mob'">
        <div class="column__item-imgwrap--mob mask"
            :style="`mask-image: url('/assets/images/${prj.shape.toLowerCase()}.svg');`">
                <div v-if="intro" class="column__item-img--mob" :style="`background-image: url(${prj.gif.url})`"></div>
            <router-link v-else :to="`/${prj.prj.uid}`">
                <div class="column__item-img--mob" :style="`background-image: url(${prj.gif.url})`"></div>
            </router-link>
        </div>
        <!-- <figcaption class="column__item-caption">
            <span>Gnostic Will</span>
            <span>2012</span>
        </figcaption> -->
    </figure>
</template>

<script>

export default {
    props: {
        prj: Object,
        intro: Boolean
        // img: Number,
        // shape: String
    },
    data() {
        return {
            realNum: 0
        }
    },
    computed: {
        // Num(){
        //     var n = this.pos
        // while(this.$store.homeContent.data.work.length - 1 > n){
        //     n = n - this.$store.homeContent.data.work.length - 1
        // } 
        //     return
        // },
        selectImg() {
            return this.$store.state.homeContent.home.data.work[this.realNum].gif.url
        },
        title() {
            const objPr = this.$store.state.prj
           const key = this.$store.state.homeContent.home.data.work[this.realNum].prj.uid
            var result = objPr.find(obj => {
                return obj.uid === key
            })
            return result.data.title
        },
        url() {
            return this.$store.state.homeContent.home.data.work[this.realNum].prj.uid
        },
        shape() {
            return this.$store.state.homeContent.home.data.work[this.realNum].shape.toLowerCase();
        }
    },
    methods: {
        changeTitle(text) {
            this.$store.commit("pushTitle", text)
            if (text) {
                this.$store.commit("pushImg", this.selectImg)
                // this.$store.commit("spreadImg")
            } else {
                this.$store.commit("removeImg")
            }
        }
    },
    mounted() {
        //    console.log() 
        if (this.$store.state.homeContent.home.data.work.length - 1 > this.pos) this.realNum = this.pos
        else {
            var m = this.pos
            while (this.$store.state.homeContent.home.data.work.length <= m) {
                m = m - this.$store.state.homeContent.home.data.work.length
            }
            this.realNum = m
        }

    },

}
</script>

<style lang="scss" scoped>
.column__item-img--mob {
    // pointer-events: none;
    width: 100%;
    height: 100%;
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.mask {
    // margin: 0;
    // -webkit-mask-image: url("../assets/images/mask/arc.svg");
    // mask-image: url("../assets/images/mask/arc.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;

    user-select: none;
    // pointer-events: none;
}

.column__item-imgwrap--mob{
    width: 70%;
    height: auto;

}

.column__item--mob{
    position: absolute;
    top: 20vh;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50vh;
    z-index: 99;
}
.column__item--intro{
    position: relative;
    // top: 20vh;
    // left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.link__intro{
    user-select: none;
}
</style>