<template>
  <div class="prj__container">
    <MainLogo />
    <!-- 
    Add the SliceZone to your template, and pass the Slice
    data and a resolver function as props.
  -->
    <SliceZone v-if="loading" :slices="document.data.body" :resolver="({ sliceName }) => slices[sliceName]" />
    <div v-if="loading" class="clap__container">
      <button class="clap__button unbutton" @click="test()"><img src="../assets/images/clap-no-bord.svg"></img></button>
      <div class="clapCount">{{ clap >= 0 ? $store.state.claps[clap].clap : 0 }}</div>
    </div>
    <div class="prjNav__container" v-if="loading">
      <router-link :to="`/${$store.state.prj[prev].uid}`" class="prjNav__button prjNav__button--left"><img
          src="../assets/images/blog-arrow.svg"></img>
        <div>{{ $store.state.prj[prev].data.title }}</div>
      </router-link>
      <router-link :to="`/${$store.state.prj[next].uid}`" class="prjNav__button prjNav__button--right">
        <div>{{ $store.state.prj[next].data.title }}</div><img src="../assets/images/blog-arrow.svg">
      </router-link>
    </div>
  </div>
</template>

<script>

import SliceZone from 'vue-slicezone'
// Import your Slices (ensure the path is correct)
import VideoHero from '../components/slices/VideoHero.vue'
import Intro from '../components/slices/intro.vue'
import Credits from '../components/slices/Credits.vue'
import HeaderPrj from '../components/slices/HeaderPrj.vue'
import Gallery from '../components/slices/Gallery.vue'
import TextBlock from '../components/slices/TextBlock.vue'
import MainLogo from '@/components/MainLogo.vue'

import { mapGetters } from 'vuex'

export default {
  //  mixins: [mousePointer, homeTweens],
  components: {
    SliceZone,
    MainLogo,
  },
  data() {
    return {
      loading: false,
      document: null,
      index: "0",
      indexClap: "",
      // Add your Slices to a "slices" object, which
      // will be used in the resolver function.
      slices: {
        VideoHero, Intro, HeaderPrj, Gallery, TextBlock, Credits
      }
    }
  },
  computed: {
    prev() {
      var k = this.index <= 0 ? this.$store.state.prj.length - 1 : this.index - 1
      return k
    },
    next() {

      var k = this.index >= this.$store.state.prj.length - 1 ? 0 : this.index + 1
      return k
    },
    clap() {

      var index = this.$store.state.claps.findIndex(p => p.uid == this.document.uid);
      return index

    },
    ...mapGetters(['claps']),
  },
  methods: {
    test() {
      if (this.clap >= 0) {
        this.$store.dispatch('updateClap', { bookId: this.clap })
      } else {
        this.$store.dispatch('addClap', {
          newBook: {
            uid: this.document.uid,
            clap: 1,
          }
        })
      }
    },
    // Define a method to query Prismic for your document.
    async getContent(uid) {
      // Query the API and assign the response to "response"
      const response = await this.$prismic.client.getByUID('project', uid)

      this.document = response
      this.loading = true

    },
    async getRoute(uid) {

      const obj = await this.$store.state.prj
      const index = obj.findIndex(object => {
        return object.uid === uid
      })
      this.index = index
    }
  },
  mounted() {
    // console.log(this.$route.params.id)
    this.getRoute(this.$route.params.id)
    // Call the API query method
    this.getContent(this.$route.params.id)
  },
  created() {

  },
  beforeRouteUpdate(to, from, next) {
    this.getContent(to.params.id);
    this.getRoute(to.params.id)
    next();
  },
};
</script>

<style lang="scss" scoped>
.prj {
  &__container {
    width: 100%;
    padding: 0 50px;

    @media screen and (max-width:768px) {
      padding: 0 0px;
      padding-top: 20px;
    }
  }
}

.prjNav {
  &__container {
    padding-top: 100px;
    padding-bottom: 50px;
    width: 100%;
    display: flex;

    @media screen and (max-width:768px) {
      flex-direction: column;
    }
  }

  &__button {
    width: 50%;
    padding: 20px 4vw;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: flex;
    font-family: Roslindale, serif;
    font-weight: normal;
    font-variation-settings: "wght" 350, "wdth" 75;
    font-size: 2.5vw;
    justify-content: space-between;
    transition: all 300ms ease-out;

    div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    @media screen and (max-width:768px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 20px;
      font-size: 20px;
    }

    @media screen and (min-width:768px) {
      &:hover {
        background: black !important;
        color: white;

        img {
          filter: invert(1);
        }
      }
    }

    img {
      transition: inherit;

      @media screen and (max-width:768px) {
        width: 50px;
        height: auto;
      }
    }

    &--left {


      &:hover {
        img {
          transform: translateX(-6px) rotate(180deg);
        }
      }

      // justify-content: flex-end;
      border-right: 1px solid black;

      @media screen and (max-width:768px) {
        border: none;
        border-top: 1px solid black;
      }

      img {
        transform: rotate(180deg);
      }
    }

    &--right {
      &:hover {
        img {
          transform: translateX(+6px);
        }
      }
    }
  }
}

.clap__container {
  display: flex;
  width: clamp(150px, 90%, 1080px);
  margin: auto;
  margin-top: 100px;
  align-items: center;
  gap: 24px;

  .clapCount {
    font-family: Roobert, Arial, Helvetica, sans-serif;
    font-size: 24px;
  }
}

.clap__button {
  border: 1.5px solid black;
  border-radius: 100%;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background: black;

    img {
      filter: invert(1);
    }
  }
}
</style>