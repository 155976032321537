import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrismicVue from '@prismicio/vue'
import linkResolver from './link-resolver' 
import vueVimeoPlayer from 'vue-vimeo-player';
import responsive from 'vue-responsive'
import { firestorePlugin } from "vuefire";


const accessToken = '' // Leave empty if your repository is public
const endpoint = 'https://thebuss-portfolio.prismic.io/api/v2' // Use your repository name

// Register plugin
Vue.use(firestorePlugin);
Vue.use(vueVimeoPlayer);
Vue.use(responsive);
Vue.use(PrismicVue, {
  endpoint,
  apiOptions: {
    accessToken
  },
  linkResolver,
});

import mailgo from "mailgo";
import "locomotive-scroll/src/locomotive-scroll.scss";
// import "./assets/scss/_index.scss";

const mailgoConfig = {
  dark: true,
};

mailgo(mailgoConfig);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
