<template>
    <div class="contacts__container">
        <h3>for collaboration or job inquires <br/> send an e-mail to</h3>
        <a class="mail" href="#mailgo" data-address="info" data-domain="thebuss.net">info@thebuss.net</a>
        <h3 class="contacts__subtitle">Multimedia exhibition design <br>
            Milan, Italy</h3>
        <div class="contacts__clapCont">
            <img src="../assets/images/clap.svg" alt="">
            <div class="contacts__clap">{{ claps }} UNTIL TODAY</div>
        </div>
        <div class="contacts__linkCont">
            <a :href="$store.state.homeContent.contacts.data.instagram.url" class="contacts__link"> <img
                    src="../assets/images/blog-arrow.svg" alt="">INSTAGRAM</a>
            <a :href="$store.state.homeContent.contacts.data.facebook.url" class="contacts__link contacts__link--right">
                <img src="../assets/images/blog-arrow.svg" alt="">FACEBOOK</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            clap: 255
        }
    },
    computed:{
        claps(){
            var tot = 0;
            this.$store.state.claps.forEach(el => {
                tot += el.clap
            });
            return tot
        }
    }
}
</script>

<style lang="scss" scoped>
.contacts {
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-right: 24px;
        padding-top: 32px;
        @media screen and (max-width:768px) {
            padding: 20px;
            justify-content: space-around;
            height: 80vh;
        }

        h3 {
            font-family: Roobert, Arial, Helvetica, sans-serif;
            text-align: center;
            font-weight: normal;
            font-size: 28px;
            line-height: 111%;
            margin-bottom: 42px;
            @media screen and (max-width:768px) {
                font-size: 24px;
            }
        }

        .mail {
            &:hover {
                text-decoration: underline;
            }

            width: 100%;
            text-align: center;
            font-family: Roslindale,
            serif;
            font-weight: normal;
            font-variation-settings: "wght" 550,
            "wdth" 75;
            font-size: 4vw;
            text-transform: uppercase;
            line-height: 120%;
            margin-bottom: 42px;
            @media screen and (max-width:768px) {
                
                font-size: 42px;
            }
        }
    }

    &__subtitle {
        text-transform: uppercase;
        margin-bottom: 96px !important;
    }

    &__clapCont {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-bottom: 52px;
    }

    &__clap {
        font-family: Roobert, Arial, Helvetica, sans-serif;
        font-size: 22px;
    }

    &__linkCont {
        width: 100%;
        display: flex;
        justify-content: space-between;

        a {
            display: flex;
            align-items: center;
            font-family: Roobert, Arial, Helvetica, sans-serif;
            font-size: 22px;
            gap: 16px;
            img{
                height: 28px;
                width: auto;
                @media screen and (max-width:768px) {
                    height: 20px;
                }
            }

            &:hover {
                img {
                    @media (hover:hover){
                    transform: translateX(14px);
                    transition: 200ms ease-out;
                    }
                }
            }
        }

        .contacts__link--right {
            justify-content: flex-end;
        }
    }
}
</style>