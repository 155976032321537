<template>
    <div class="title__container" :class="enter && 'title__container--enter'">
        <h1>{{ $store.state.titlePrj }}</h1>
    </div>
</template>

<script>
export default {
    props: {
        enter: Boolean
    }
}
</script>

<style lang="scss" scoped>
.title__container {
    user-select: none;
    position: absolute;
    width: 800px;
    top: 30vh;
    left: calc(50vw - 400px);
    z-index: 999;
    mix-blend-mode: difference;
    @media screen and (max-width:768px) {
        top: 40vh;
            width: 90%;
            left: calc(50vw - 45%);
        }

    h1 {
        text-transform: uppercase;
        font-family: Roslindale;
        // font-weight: 550;
        font-weight: normal;
        font-variation-settings: "wght" 550, "wdth" 75;
        font-size: 80px;
        line-height: 95%;
        text-align: center;
        letter-spacing: 0.04em;
        color: white;
        @media screen and (max-width:768px) {
            font-size: 10vw;
        }

    }
    &--enter{
        mix-blend-mode: normal;
        z-index: 999999;
        h1{
            color: black;
        }
    }
}
</style>