<template>
    <div class="prjList__main">
        <div v-if="type === 'prj'" class="prjList__container">
            <prj-list-item v-for="prj in $store.state.prjbuss" :key="prj.id" :title="prj.data.title" :year="prj.data.year" :page="prj.uid"></prj-list-item>
            <div v-if="$store.state.prjn03" class="prjList__divider">N!03 WORKS</div>
            <prj-list-item v-for="prj in $store.state.prjn03" :key="prj.id" :title="prj.data.title" :year="prj.data.year" :page="prj.uid"></prj-list-item>
        </div>
        <div v-else-if="type==='blog'" class="prjList__container">
            <blog-item-list v-for="(article, index) in $store.state.homeContent.blog.data.article" :key="index + '_article'" :title="article.title"
                :intro="article.intro"
                :tags="article.tags" :url="article.medium_url.url" />
        </div>
        <div v-else-if="type==='about'" class="prjList__container">
        <about-side-comp />
        </div>
        <div v-else class="prjList__container">
            <contatct-side-comp />
        </div>
    </div>
</template>

<script>
import AboutSideComp from './aboutSideComp.vue'
import BlogItemList from './blogItemList.vue'
import ContatctSideComp from './contatctSideComp.vue'
import PrjListItem from './PrjListItem.vue'
export default {
    components: { PrjListItem, BlogItemList, AboutSideComp, ContatctSideComp },
    props: {
        prj: Object,
        type: String
    },
    mounted() {
    //   console.log(this.$store.state.homeContent.blog.data.article)  
    },
}
</script>

<style lang="scss" scoped>
.prjList__main {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 22px;
    // overflow-y: hidden;
    overflow-y: auto;
    scrollbar-color: #999 #000;

    &:hover {
        // overflow-y: auto;
    }

}

.prjList__container {

    display: flex;
    flex-direction: column;
    padding-right: 4px;
    align-items: flex-start;
    // align-self: stretch;
    // height: 100%;
    // padding-bottom: 22px;

}

.prjList__main::-webkit-scrollbar {
    width: 3px;
    /* Mostly for vertical scrollbars */
    height: 10px;
    /* Mostly for horizontal scrollbars */
    //   border-radius: 10px;
}

.prjList__main::-webkit-scrollbar-thumb {
    /* Foreground */
    background: #000;
    //   border-radius: 6px;
}

.prjList__main::-webkit-scrollbar-track {
    /* Background */
    background: #3330;
}

.prjList__divider{
        font-family: Roobert, Arial, Helvetica, sans-serif;
            font-size: 18px;
            border: 1px solid black;
            border-radius: 40px;
            padding: 4px 10px;
            margin-top: 10px;
            margin-bottom: 30px;
            
    }
</style>