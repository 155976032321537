<template>
    <div class="header__container">
        <h1>{{ slice.primary.title_prj }}</h1>
        <div class="header__labelContainer">
            <span>CLIENTE</span>
            <div class="header__infoCont">
                <div class="header__client">{{ slice.primary.client_prj }}</div>
                <div class="header__year">{{ slice.primary.years_prj }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderPrj",
    props: {
        slice: Object
    },
    mounted() {
    },

}
</script>

<style lang="scss" scoped>
.header {
    &__container {
        margin: auto;
        margin-top: 62px;
        width: clamp(150px, 90%, 1080px);
        @media screen and (max-width:768px) {
            margin-top: 32px
        }

        h1 {
            text-align: center;
            font-family: Roslindale, serif;
            font-weight: normal;
            font-variation-settings: "wght" 350, "wdth" 75;
            font-size: 5.5vw;
            text-transform: uppercase;
            line-height: 110%;
            margin-bottom: 42px;
            @media screen and (max-width:768px) {
                font-size: 9vw;
            }
        }
    }

    &__labelContainer {
        font-family: Roobert, Arial, Helvetica, sans-serif;
        font-size: 14px;
        @media screen and (max-width:768px) {
            font-size: 14px;
            text-align: center;
            
        }
    }

    &__infoCont {
        display: flex;
        align-items: center;
        @media screen and (max-width:768px) {
            align-items: flex-start;
            justify-content: center;
            margin-top: 8px;
        }
    }

    &__client {
        font-size: 26px;
        margin-right: 14px;
        @media screen and (max-width:768px) {
            font-size: 18px;
            text-align: left;
            margin-right: 8px;
        }
    }

    &__year {
        padding: 3px 10px;
        font-size: 14px;
        border: 1px solid black;
        border-radius: 20px;
    }

}
</style>