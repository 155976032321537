<template>
    <!-- <transition name="leftMob"> -->
    <transition appear :appear-active-class="left ? 'leftMob-enter-active' : 'rightMob-enter-active'"
        :name="left ? 'leftMob' : 'rightMob'">

        <div class="sideMob__container">
            <div class="sideMob__divider"></div>
            <div class="prjMob__container prjMob__container--prj" v-if="!toogle && left">
                <prj-list-item v-for="prj in $store.state.prjbuss" :key="prj.id" :title="prj.data.title"
                    :year="prj.data.year" :page="prj.uid"></prj-list-item>
                <div v-if="$store.state.prjn03" class="prjList__divider">N!03 WORKS</div>
                <prj-list-item v-for="prj in $store.state.prjn03" :key="prj.id" :title="prj.data.title"
                    :year="prj.data.year" :page="prj.uid"></prj-list-item>
            </div>
            <div class="prjMob__container prjMob__container--blog" v-else-if="toogle && left">
                <blog-item-list v-for="(article, index) in $store.state.homeContent.blog.data.article"
                    :key="index + '_article'" :title="article.title" :intro="article.intro" :tags="article.tags"
                    :url="article.medium_url.url" />
            </div>
            <div class="prjMob__container prjMob__container--about" v-else-if="!toogle && !left">
                <about-side-comp />
            </div>
            <div class="prjMob__container prjMob__container--about" v-else-if="toogle && !left">
                <contatct-side-comp />
            </div>
            <div class="toggleMob__container">
                <!-- <div class="toggle__container" :class="!left && 'toggle__container--right'"> -->
                <side-toggle :Switch="!toogle" :Label="firstTog" @click.native="Swithc(!toogle)" />
                <side-toggle :Switch="toogle" :Label="secondTog" @click.native="Swithc(toogle)" />
            </div>
        </div>
    </transition>
</template>

<script>
import PrjListItem from './PrjListItem.vue'
import SideToggle from './sideToggle.vue'
import BlogItemList from './blogItemList.vue'
import AboutSideComp from './aboutSideComp.vue'
import ContatctSideComp from './contatctSideComp.vue'
export default {
    components: { PrjListItem, SideToggle, BlogItemList, AboutSideComp, ContatctSideComp },
    props: {
        left: Boolean,
        firstTog: String,
        secondTog: String,
        type: String
    },
    data() {
        return {
            toogle: false
        }
    },
    watch: {
        '$route'(to, from) {
            this.$store.commit("closeMM", "left")
            this.$store.commit("closeMM", "right")
        }
    },
    methods: {
        Swithc(bool) {
            if (!bool) this.toogle = !this.toogle
            var cont = document.querySelector(`.sideMob__container`)
            cont.scrollTop = 0
        },
        onLeave() {
            // console.log("DIOMADONNA")
        }
    },

}
</script>

<style lang="scss" scoped>
.sideMob {
    &__container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        @include main-bg-p3;
        z-index: 998;
        padding-top: 61px;
        overflow: scroll;
        // background: red;
    }

    &__divider {
        width: 100%;
        border-bottom: 1px solid black;
        position: fixed;
    }

}

.prjMob {
    &__container {
        padding-top: 32px;
        padding-bottom: 72px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &--prj {
            @media screen and (max-width:768px) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        &--blog {

            // @media screen and (max-width:768px) {
            //     padding-left: 20px;
            //     padding-right: 20px;
            // }
        }
    }
}

.prjList__divider {
    font-family: Roobert, Arial, Helvetica, sans-serif;
    font-size: 16px;
    border: 1px solid black;
    border-radius: 40px;
    padding: 4px 10px;
    margin-top: 10px;
    margin-bottom: 30px;

}

.toggleMob {
    &__container {
        width: 100vw;
        height: 60px;
        @include main-bg-p3;
        position: fixed;
        left: 0;
        bottom: 0;
        border-top: 1px solid black;
        display: flex;
        justify-content: flex-start;
        gap: 48px;
        font-family: Roober, Arial, Helvetica, sans-serif;
        font-size: 24px;
        align-items: center;
        padding: 0 20px;
    }
}

.leftMob-enter-active {
    // transition-delay: 1s;
    //   transition: all 10s ease;
    animation: lf 700ms;
}

.leftMob-leave-active {
    // animation: go 2s reverse;
    animation: lf 700ms reverse backwards;
    //   transition: all 10s ease;

}

.rightMob-enter-active {
    // transition-delay: 1s;
    //   transition: all 10s ease;
    animation: rg 700ms;
}

.rightMob-leave-active {
    // animation: go 2s reverse;
    animation: rg 700ms reverse backwards;
    //   transition: all 10s ease;

}

// .leftMob-enter-from,
.leftMob-leave-to {
    // opacity: 0;
}

// .leftMob-enter-to,
.leftMob-leave-from {
    // opacity: 1;
}

@keyframes lf {
    from {
        transform: translateX(-100vw);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes rg {
    from {
        transform: translateX(100vw);
    }

    to {
        transform: translateX(0);
    }
}
</style>