<template>
    <div class="intro__container">
        <!-- <p class="intro__highlight">{{ slice.pryma }}</p> -->
        <prismic-rich-text :field="slice.primary.highlight_intro"
            class="intro__highlight" :class="slice.primary.english && 'eng eng_highlight'"/>
        <prismic-rich-text :field="slice.primary.intro" class="intro__text" :class="slice.primary.english && 'eng eng_text'"/>
    </div>
</template>

<script>
export default {
    name: "Intro",
    props: {
        slice: Object
    },
    data() {
        return {

        }
    },

    mounted() {
    },

}
</script>

<style lang="scss">
.intro {
    &__container {
        margin: auto;
        margin-top: 32px;
        width: clamp(150px, 90%, 1080px);
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        font-family: Roobert, Arial, Helvetica, sans-serif;
        @media screen and (max-width:768px) {
            flex-direction: column;
            gap: 36px;
        }
    }

    &__highlight {
        flex-grow: 1;
        width: 50%;
        @media screen and (max-width:768px) {
            width: 100%;
        }

        p {
            font-size: 22px;
            line-height: 30px;
            text-transform: uppercase;
        }
    }

    &__text {
        flex-grow: 1;
        width: 50%;
        font-size:16px;
        @media screen and (max-width:768px) {
            width: 100%;
        }
    }
}

.eng{
    color: #595959;
}

.eng_highlight{
    p{
    font-size: 20px;
    }
}
.eng_text{
    p{
    font-size: 14px;
    }
}
</style>

