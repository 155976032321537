<template>
    <div class="menu__container" :class="left ? 'menu__container--left' : 'menu__container--right'">
        <div class="content__container">
            <div class="toggle__container" :class="!left && 'toggle__container--right'">
                <side-toggle :Switch="!toogle" :Label="firstTog" @click.native="Swithc(!toogle)" />
                <side-toggle :Switch="toogle" :Label="secondTog" @click.native="Swithc(toogle)" />
            </div>
            <project-list v-if="!toogle && left" type="prj" />
            <project-list v-else-if="toogle && left" type="blog" />
            <project-list v-else-if="!toogle && !left" type="about" />
            <project-list v-else-if="toogle && !left" type="contact" />
        </div>
        <div v-if="left" class="label__container">
            <button class="unbutton" @click="toggleMenu">WHAT <img src="../assets/images/arrow-d.svg" alt=""></button>
        </div>
        <div v-else class="label__container label__right">
            <button class="unbutton" @click="toggleMenu">WHO <img src="../assets/images/arrow.svg" alt=""></button>
        </div>
    </div>
</template>

<script>
import sideToggle from './sideToggle.vue'
import ProjectList from './ProjectList.vue'
export default {
    components: { sideToggle, ProjectList },
    props: {
        left: Boolean,
        firstTog: String,
        secondTog: String
    },
    data() {
        return {
            open: false,
            toogle: false
        }
    },
    watch: {
        '$route'(to , from){
          this.open = false;
          var ele = document.querySelector(`.menu__container--${this.left ? 'left' : 'right'}`)  
            ele.style.transform = `translateX(0)`
            ele.lastChild.lastChild.lastChild.style.transform = `rotate3d(0,0,1, 0)`
        }
    },
    methods: {
        toggleMenu(e) {
            var el = e.target.nodeName === 'IMG' ? e.target.parentElement.parentElement.parentElement : e.target.parentElement.parentElement
            var frec = e.target.nodeName === 'IMG' ? e.target : e.target.lastChild
            var dir = !this.open && this.left ? 1 : -1;
            if (this.open) {

                el.style.transform = `translateX(0)`
                frec.style.transform = `rotate3d(0,0,1, 0)`
            } else {
                // this.open=true
                el.style.transform = `translateX(calc(${dir * 50}vw + ${-dir * 50}px))`
                frec.style.transform = `rotate3d(0,0,1, 180deg)`
            }
            this.open = !this.open
        },
        Swithc(bool) {
            if (!bool) this.toogle = !this.toogle
            var cont = document.querySelector(`.menu__container--${this.left ? 'left' : 'right'}`).firstChild.lastChild
            cont.scrollTop = 0
        }
    },
    mounted() {
    },
}
</script>

<style lang="scss" scoped>
.menu__container {

    @include main-bg-p3;
    position: fixed;
    top: 0;
    width: 50vw;
    height: 100vh;
    z-index: 9999;
    display: flex;
    transition: 600ms ease-in;
}

.menu__container--right {
    padding-right: 50px;
    right: calc(-50vw + 50px);
    flex-direction: row-reverse;
    // transform: translateX(calc(50vw - 50px));
}

.menu__container--left {
    padding-left: 50px;
    left: calc(-50vw + 50px);
    // transform: translateX(calc(-50vw + 50px));
}

.label__container {
    width: 50px;
    // background: peachpuff;
    font-family: Roobert;
    font-size: 24px;
    padding: 24px 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    button {
        &:hover {
            // text-decoration: underline;

            // transition: 200ms;
            img {
                transform: translateY(6px);
            }
        }

        transform: rotate(-90deg) translateX(-40px);
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: 20px;
        padding: 0 20px;
        // background: salmon;
        cursor: pointer;
        height: auto;

        img {
            transition: 600ms ease-out;
        }
    }

}

.label__right {
    button {
        transform: rotate(-90deg) translate(-36px, 0);

        img {}

        &:hover {

            img {
                transform: translateY(-6px);
            }
        }
    }
}

.content__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-block: 32px;
    padding-top: 5px;

    .label__container {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        gap: 20px;
    }
}

.toggle__container {
    display: flex;
    justify-content: flex-start;
    gap: 48px;
    font-family: Roober, Arial, Helvetica, sans-serif;
    font-size: 24px;
    margin-top: 18px;
    margin-bottom: 20px;

    &--right {
        justify-content: flex-end;
    }
}
</style>