import 'firebase/compat/firestore'
import firebase from "firebase/compat/app"
 

const firebaseConfig = {
  apiKey: "AIzaSyDDSlOsZCLQiHyoHjzmdcytPo1mUR4wu2A",
  authDomain: "bussclap.firebaseapp.com",
  projectId: "bussclap",
  storageBucket: "bussclap.appspot.com",
  messagingSenderId: "999094979807",
  appId: "1:999094979807:web:bb13bbe6eac2fb381cad6a",
  measurementId: "G-KX3C8B70T7"
}

export const db = firebase.
  initializeApp(firebaseConfig)
  .firestore()