<template>
    <div class="logo__container">
        <router-link to="/">
        <img src="../assets/images/thebuss-logo-w.svg" alt="thebuss logo" class="logo">
        </router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.logo__container {
    // width: 100%;
    position: absolute;
    top: 30px;
    
    left: calc(50vw - clamp(45px, 5.5vw, 125px));
    display: flex;
    justify-content: center;
    z-index: 997;
    // padding-top: 30px;
            -webkit-mix-blend-mode: exclusion;
    mix-blend-mode: exclusion;
    -moz-mix-blend-mode: exclusion;
    -o-mix-blend-mode: exclusion;
    -ms-mix-blend-mode: exclusion;
    @media screen and (max-width: 768px) {
        top: 90px
    }
}

.logo {
    width: clamp(90px, 11vw, 250px);
    height: auto;
    // filter: invert(1);

}
</style>