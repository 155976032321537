<template>
    <div class="image__container">
        <div class="block__container">
            <tiny-slider v-bind="tinySliderOptions" ref="tinySlider" class="gallery__container">
                <div v-for="(img, index) in slice.items" :key="'img:' + index">
                    <prismic-image :field="img.image_slide" @click.prevent="alt" v-responsive.lg.xl/>
                    <prismic-image :field="img.image_slide" @touchend="alt" v-responsive.sm.xs.md/>

                </div>
                <!-- <div>#1</div>
      <div>#2</div>
      <div>#3</div>
      <div>#4</div>
      <div>#5</div>
      <div>#6</div> -->
            </tiny-slider>
            <!-- <prismic-image :field="slice.primary.image" v-if="slice.primary.image"/>
      <div class="dida__container" v-if="slice.items[0]">
        <div v-if="slice.items[0].ph_cred[0]">{{ slice.items[0].ph_cred[0].text }}</div>
        <div v-if="slice.items[0].image_dida[0]" class="dida">{{ slice.items[0].image_dida[0].text }}</div>
      </div> -->
        </div>
        <div class="control__container">
            <div class="control__arrowCont">
                <div class="arrow__container"><button @click="forward" :class="current > totImg - 1 ? 'btnDeactive' : ''"
                        class="unbutton arrow__btn arrow__btn--left"><img class=""
                            src="../../assets/images/blog-arrow.svg" alt=""></button>
                    <div class="arrow__index">{{ current }} / {{ totImg }}</div><button @click="back"
                        :class="current <= 1 ? 'btnDeactive' : ''" class="unbutton arrow__btn arrow__btn--right"><img
                            class="" src="../../assets/images/blog-arrow.svg" alt=""></button>
                </div>
            </div>
            <div class="control__navCont">
                <div class="control__navFlex">
                    <div v-for="(img, index) in slice.items.slice(current - 1)" :key="'img:' + index"
                        :style="`background-image: url(${img.image_slide.mobile.url})`">
                        <!-- <prismic-image :field="img.image_slide.mobile" @click.prevent="alt" /> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider';


export default {
    name: "gallery",
    components: { 'tiny-slider': VueTinySlider },
    props: {
        slice: Object,
    },
    data() {
        return {
            loading: false,
            totImg: "",
            current: 1,
            tinySliderOptions: {
                mouseDrag: true,
                loop: false,
                controlsPosition: "bottom",
                items: 1,
                gutter: 0,
                swipeAngle: 45,
                nav: false,
                startIndex: 0,
                controls: false,
                controlsText: ["sasaasa", "shsshsh"],
                preventScrollOnTouch: 'auto'
                // preventScrollOnTouch: true
                //   navAsThumbnails: true,
                //   navContainer: "#custom"
                //   navContainer: "#custom",
            }
        }
    },
    computed: {
        // slide() {

        //     if (this.loading) {
        //         var k = this.$refs.tinySlider.slider.getInfo();
        //         console.log(k)
        //         return k.index
        //     }
        //     else return "0"
        // }
    },
    methods: {
        test(){
            console.log("TEEEESTSTT")
        },
        back() {
            if (this.current > 1) {
                this.$refs.tinySlider.slider.goTo('prev')
                this.current--
            }

        },
        forward() {
            if (this.current < this.totImg) {
                this.$refs.tinySlider.slider.goTo('next')
                this.current++
            }
        },
        // test() {
        //     var k = this.$refs.tinySlider.slider.getInfo();

        // },
        alt() {
            setTimeout(() => {
                var k = this.$refs.tinySlider.slider.getInfo();
                console.log(k.index)
                this.current = k.index + 1
            }, 400)
        }
    },
    mounted() {
        this.loading = true
        var k = this.$refs.tinySlider.slider.getInfo();
        this.totImg = k.slideCount
        // this.$refs.tinySlider.addEventListener('click', function () {
        //     var k = this.$refs.tinySlider.slider.getInfo();
        //     // this.totImg = 
        //     console.log(k)
        // }, false);
    },
};
</script>
<style lang="scss">
@import 'tiny-slider/src/tiny-slider';

.image__container {
    margin: auto;
    margin-top: 100px;
    //   width: clamp(150px, 90%, 1440px);
    // width: clamp(150px, 100%, 1080px);
    width: 100%;


    img {
        width: 100%;
        height: auto;
    }

    //   overflow: hidden;
}

.block__container {
    //   width: 66.66%;
    width: clamp(150px, 100%, 1080px);
    margin: auto;
    display: flex;
    height: auto;
    justify-content: center;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.control {
    &__container {
        display: flex;
        // width: 100%;
        margin: auto;
        width: clamp(150px, 100%, 1080px);
    }

    &__arrowCont {
        height: 200px;
        width: 50%;
        // padding-left: calc(100% - 540px);
        // background: green;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        align-items: flex-start;
        padding: 20px;
        @media screen and (max-width:768px) {
            width: 33%;
            padding-left: 0;
        }

        .arrow {
            &__container {
                display: flex;
                align-items: center;
                gap: 16px;
            }

            &__btn {
                background: none;
                padding: 4px;
                transition: 200ms ease-out;
                cursor: pointer;

                &--left {
                    img {
                        transform: rotate(180deg);
                        @media screen and (max-width:768px) {
         width: 32px;
         height: auto;
        }
                    }

                    &:hover {
                        img {
                            transition: 200ms ease-out;
                            transform: translateX(-4px) rotate(180deg);
                        }
                    }
                }

                &--right {

                    &:hover {
                        img {
                            transition: 200ms ease-out;
                            transform: translateX(4px);
                        }
                    }
                    @media screen and (max-width:768px) {
            display: none;
        }
                }
            }

            &__index {
                font-family: Roobert, Arial, Helvetica, sans-serif;
                font-size: 16px;
                @media screen and (max-width:768px) {
                    font-size:14px
                }
            }
        }

    }

    &__navCont {
        height: 200px;
        width: 50%;
        // padding-right: calc(100% - 540px);
        display: flex;
        overflow: hidden;
        padding: 20px 0;
        @media screen and (max-width:768px) {
            width: 75%;
        }

    }

    &__navFlex {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 8px;
        overflow: show;



        div {
            &:first-child {
                border: 5px solid;
                border-color: $main-color;
            }

            width: 162px;
            height: 123px;
            @media screen and (max-width:768px) {
                width: 100px;
                height: 90px;
        }
            // overflow: hidden;
            background-size: cover;
            background-position: center;

            img {
                width: auto;
                height: 100%;
            }
        }
    }



}

.btnDeactive {
    opacity: 10%;
    cursor: default !important;
    &:hover {
        img {
            transform: none !important;
        }
    }
}
</style>
