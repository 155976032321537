<template>
    <div class="about__container">
        <h2>La Scienza delle Soluzioni Immaginarie</h2>
        <h3>Creiamo racconti multimediali che vedono come protagonisti informazione ed intrattenimento</h3>
        <div class="about__contContainer">
            <div class="about__labelCont"><img src="../assets/images/blog-arrow.svg" alt="">
                <div>COLLABORATORS</div>
            </div>
            <div class="about__listCont">
                <div v-for="(colab, index) in $store.state.homeContent.about.data.collaborators" :key="index + '_colab'">
                    {{ colab.single_collaborator }}</div>
            </div>
        </div>
        <div class="about__contContainer about__contContainer--alt">
            <div class="about__labelCont about__labelCont--alt"><img src="../assets/images/blog-arrow.svg" alt="">
                <div>SELECTED CLIENTS</div>
            </div>
            <div class="about__listCont about__listCont--alt">
                <div v-for="(colab, index) in $store.state.homeContent.about.data.select_clients" :key="index + '_colab'">
                    {{ colab.client }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.about {

    &__container {
        padding-right: 24px;
        padding-top: 32px;
        @media screen and (max-width:768px) {
            padding: 20px 20px;
        }

        h2 {
            text-align: center;
            font-family: Roslindale, serif;
            font-weight: normal;
            font-variation-settings: "wght" 550, "wdth" 75;
            font-size: 4vw;
            text-transform: uppercase;
            line-height: 120%;
            margin-bottom: 32px;
            @media screen and (max-width:768px) {
                font-size: 42px;
            }
        }

        h3 {
            font-family: Roobert, Arial, Helvetica, sans-serif;
            text-align: center;
            font-weight: normal;
            font-size: 28px;
            line-height: 120%;
            margin-bottom: 62px;
            @media screen and (max-width:768px) {
                font-size: 22px;
            }
        }
    }

    &__contContainer {
        align-items: flex-start;
        justify-content: center;
        display: flex;
        gap: 24px;
        margin-top: 20px;
        margin-bottom: 50px;
        @media screen and (max-width:768px) {
            flex-direction: column;
        }
        &--alt {
            @media screen and (max-width:768px) {
            flex-direction: column;
        }
            flex-direction: row-reverse;
        }
    }

    &__labelCont {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 50%;
        gap: 16px;
        @media screen and (max-width:768px) {
            justify-content: flex-start;
            margin: auto;
        }

        &--alt {
            flex-direction: row-reverse;
            @media screen and (max-width:768px) {
                
                flex-direction: row;
            }

            img {
                transform: rotate(180deg);
            }
        }

        img {
            height: 32px;
            width: auto;
            @media screen and (max-width:768px) {
                display: none;
            }
        }

        div {
            font-family: Roobert, Arial, Helvetica, sans-serif;
            font-size: 20px;
        }
    }

    &__listCont {
        width: 50%;
        @media screen and (max-width:768px) {
            margin: auto;
            
        }
        div {
            font-family: Roobert, Arial, Helvetica, sans-serif;
            font-size: 20px;
        }

        &--alt {
            div {
                text-align: right;
                @media screen and (max-width:768px) {
            text-align: left;
            
        }
            }
        }
    }
}
</style>