<template>
    <div class="topbar__container">
        <div class="topbar__butCont topbar__butCont--left">
            <button v-if="$store.state.who" class="unbutton" @click="closeMenuM('who')">
                <img src="../assets/images/closeBuss.svg"
                    alt=""></img></button>
            <!-- <button v-if="$store.who">Close</button> -->
            <button v-else class="unbutton btDef" @click="openMenuM('what')"><img src="../assets/images/arrow-d.svg"
                    alt=""></img>
                WHAT</button>
        </div>
        <div class="topbar__butCont topbar__butCont--right">
            <button v-if="$store.state.what" class="unbutton" @click="closeMenuM('what')">
                <img src="../assets/images/closeBuss.svg"
                    alt=""></img></button>
            <button v-else class="unbutton button__default" @click="openMenuM('who')">WHO<img
                    src="../assets/images/arrow-d.svg" alt="">
            </button>
        </div>

    </div>
</template>

<script>
export default {
    methods: {
        openMenuM(side) {
            this.$store.commit("openMM", side)
        },
        closeMenuM(side) {
            this.$store.commit("closeMM", side)
        }
    },
}
</script>

<style lang="scss" scoped>
.topbar {
    &__container {
        @include main-bg-p3;
        position: fixed;
        top: 0;
        width: 100vw;
        height: 60px;
        z-index: 9999;
        display: flex;
        padding: 0 20px;
        align-items: center;
        justify-content: space-between;
    }

    &__butCont {
        &--left {
            .btDef {
                img {
                    transform: rotate(-90deg);
                }
            }
        }

        &--right {
            .button__default {
                img {
                    transform: rotate(90deg);
                }
            }
        }

        button {
            display: flex;
            align-items: center;
            gap: 11px;
            font-family: Roober, Arial, Helvetica, sans-serif;
            font-size: 22px;
        }
    }
}
</style>