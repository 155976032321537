<template>
    <div class="textBlock__container" :class="slice.primary.side && 'right'">
        <prismic-rich-text :field="slice.primary.text" :class="slice.primary.english && 'eng'"/>
        <div class="filler"></div>
    </div>
</template>
<script>
export default {
    name: "HeaderPrj",
    props: {
        slice: Object
    },
    mounted() {
    },

}
</script>

<style lang="scss" scoped>
.textBlock {
    &__container {
        display: flex;
        width: clamp(150px, 90%, 1080px);
        gap: 20px;
        margin: auto;
        margin-bottom: 85px;
        margin-top: 85px;


        div {
            width: 50%;
            font-family: Roobert, Arial, Helvetica, sans-serif;
            font-size: 16px;
            line-height: 125%;
            @media screen and (max-width:768px) {
             width: 100%;
            }

        }

        .filler {
            @media screen and (max-width:768px) {
                display: none;
            }
        }
    }

}

.right {
    flex-direction: row-reverse;
}

.eng{

    font-size: 14px !important;
}
</style>