<template>
    <div class="credits__container">
        <div class="credits__labelCont" :class="slice.primary.titolo && 'credits__labelCont--titolo'">
            <div v-if="slice.primary.titolo" class="credits__titolo">CREDITS</div>
            <div class="credits__ruolo">{{ slice.primary.ruolo }}</div>
        </div>
        <div class="credits__nameCont" :class="slice.primary.titolo && 'credits__nameCont--titolo'">
            <div v-for="(name, index) in slice.items" :key="index + ':name.nome'">{{ name.nome }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        slice: Object,
    },
    mounted() {
    },
}
</script>

<style lang="scss" scoped>
.credits {
    &__container {
        display: flex;
        width: clamp(150px, 90%, 1080px);
        gap: 20px;
        margin: auto;
        font-family: Roobert, Arial, Helvetica, sans-serif;
        margin-bottom: 40px;
        height: 123px;
        @media screen and (max-width:768px) {
            // flex-direction: column;
        }

        div {
            // width: 50%;
        }
    }

    &__labelCont {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        width: 50%;
        &--titolo {
            justify-content: space-between;
            @media screen and (max-width:768px) {
                justify-content: flex-end;
            }
        }
    }

    &__ruolo {
        text-align: right;
        font-size: 16px;
        text-transform: uppercase;
    }

    &__titolo {
        font-size: 22px;
        @media screen and (max-width:768px) {
            display: none;
        }
    }

    &__nameCont {
        width: 50%;
        // padding-top: 2px;

        &--titolo {
            padding-top: 8px;
            @media screen and (max-width:768px) {
                padding-top: 0;
            }
        }

        div {
            font-size: 16px;
            text-transform: uppercase;
        }
    }

}
.titleMob{
    @media screen and (min-width:768px) {
        display: none;
    }
}
</style>