<template>
    <div class="toggle__container">
        <button class="unbutton toggle">
            <div class="circle" :class="Switch && 'active'"></div>{{ Label }}
        </button>
    </div>
</template>

<script>
export default {
    props: {
        Switch: Boolean,
        Label: String
    },
    methods: {
    },
}
</script>

<style lang="scss" scoped>
.toggle__container {
    .toggle {
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        font-size: 20px;

        &:hover {
            @media (hover: hover) {
                .circle {
                    background: #0005;
                }
            }
        }

        .circle {
            display: block;
            width: 24px;
            height: 24px;
            border: 1px solid black;
            border-radius: 20px;
        }

        .active {
            background: black;
        }
    }
}
</style>