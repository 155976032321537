<template>
  <figure class="column__item">
    <!-- <div class="column__item-imgwrap mask" :data-pos="pos"
            :style="`mask-image: url('/assets/images/${shape}.svg');`"> -->
    <div class="column__item-imgwrap mask" :data-pos="pos">
      <!-- <router-link :to="`/${url}`"> -->
      <!-- <div class="column__item-img" @mouseenter="changeTitle(title)" @mouseleave="changeTitle('')"
                    :style="`background-image: url(${$store.state.imgPrj || selectImg})`"></div> -->
      <svg class="column__item-img" width="230px" heigth="230px" viewBox="0 0 230 230">
        <defs>
          <image id="photo" width="100%" height="100%" preserveAspectRatio="xMidYMid slice"
            :xlink:href="dataPrj.gif.url" clip-path="url(#myClip)"></image>
          <clipPath id="myClip">
            <path
              :d="clip[dataPrj.shape.toLowerCase()]"
              fill="black" />
          </clipPath>
        </defs>

        <router-link :to="`/${dataPrj.prj.uid}`">
          <use class="hover" xlink:href="#photo" @mouseenter="changeTitle(title)" @mouseleave="changeTitle('')"/>
        </router-link>
      </svg>
    </div>
  </figure>
</template>

<script>

export default {
  props: {
    pos: Number,
    dataPrj: Object
    // img: Number,
    // shape: String
  },
  data() {
    return {
      realNum: 0,
      imma: "",
      clip: {
        "bubble" : "M115 190.42C103.735 197.786 90.6648 202 76.7267 202C34.3517 202 0 163.049 0 115C0 66.9512 34.3517 28 76.7267 28C90.6648 28 103.735 32.2141 115 39.5801C126.265 32.2141 139.335 28 153.273 28C195.648 28 230 66.9512 230 115C230 163.049 195.648 202 153.273 202C139.335 202 126.265 197.786 115 190.42Z",
        "arc" : "M25 89.6207C25 40.1245 65.2944 0 115 0C164.706 0 205 40.1245 205 89.6207V230H25V89.6207Z",
        "hourglass" : "M22 174.096L68.2373 115.027L22 57.0137L68.2373 -1H161.763L208 58.0685L161.763 115.027L208 174.096L160.712 230H65.0847L22 174.096Z",
        "circle" : "M229 115C229 178.513 177.513 230 114 230C50.4873 230 -1 178.513 -1 115C-1 51.4873 50.4873 0 114 0C177.513 0 229 51.4873 229 115Z",
        "curve" : "M230 0C102.975 0 0 102.975 0 230H148.432C148.432 184.948 184.948 148.432 230 148.432V0Z ",
        "ellipse" : "M0 115C0 79.1015 29.2182 50 65.2607 50H164.739C200.782 50 230 79.1015 230 115C230 150.899 200.782 180 164.739 180H65.2608C29.2182 180 0 150.899 0 115Z",
        "flag" : "M0 135.408C0 135.408 65.7153 133.417 74.6781 69.6947C83.641 5.9726 151.34 0 151.34 0L230 97.574C230 97.574 168.267 90.6024 154.33 154.325C140.393 218.047 111.514 227.009 79.6519 229L0 135.408Z",
        "parallelepiped" : "M74.0824 3H230L155.918 226.109H0L74.0824 3Z",
        "polygon" : "M18 56.0697L44.687 227.711L155.883 230L212.222 138.458L188.5 0H130.679L18 56.0697Z",
        "polyhedron" : "M226.542 139.94C228.688 138.694 230.006 136.398 230 133.917L229.911 97.532C229.905 95.0507 228.576 92.7611 226.424 91.5257L195.549 73.8025C193.398 72.5673 192.068 70.2779 192.062 67.7968L191.974 33.0404C191.968 30.5594 190.638 28.2701 188.487 27.0348L156.934 8.92202C154.782 7.68681 152.135 7.69329 149.989 8.93902L116.297 28.5C115.934 28.7108 115.521 28.8219 115.101 28.8219C114.685 28.8219 114.277 28.7129 113.916 28.5058L79.8039 8.922C77.6523 7.68679 75.0052 7.69309 72.8596 8.93851L41.3895 27.205C39.2431 28.4509 37.9246 30.7474 37.9309 33.2292L38.0186 67.6157C38.0249 70.0973 36.7067 72.3937 34.5605 73.6396L3.4581 91.6963C1.3122 92.9421 -0.00602218 95.2381 2.06869e-05 97.7194L0.0886313 134.104C0.0946742 136.586 1.42409 138.875 3.57605 140.111L38.4579 160.135C38.5494 160.187 38.6619 160.187 38.7534 160.135C38.9511 160.021 39.1975 160.164 39.1981 160.392L39.288 197.327C39.294 199.808 40.6234 202.098 42.7754 203.333L74.3278 221.446C76.4795 222.681 79.1267 222.674 81.2723 221.429L116.09 201.215C116.161 201.174 116.204 201.099 116.204 201.017V200.9C116.204 200.839 116.254 200.789 116.316 200.789C116.378 200.789 116.428 200.839 116.428 200.9V201.136C116.428 201.26 116.494 201.375 116.602 201.437L151.465 221.446C153.617 222.681 156.264 222.674 158.409 221.429L189.873 203.162C192.019 201.917 193.337 199.621 193.331 197.139L193.25 163.285C193.244 160.803 194.562 158.507 196.708 157.262L226.542 139.94Z",
        "quadrefoil" : "M196.176 115C206.57 125.388 213 139.743 213 155.6C213 187.301 187.301 213 155.6 213C139.743 213 125.388 206.57 115 196.175C104.612 206.57 90.2566 213 74.3997 213C42.6987 213 17 187.301 17 155.6C17 139.743 23.4296 125.388 33.824 115C23.4296 104.612 17 90.2567 17 74.4C17 42.6989 42.6987 17 74.3997 17C90.2566 17 104.612 23.4299 115 33.8246C125.388 23.4299 139.743 17 155.6 17C187.301 17 213 42.6989 213 74.4C213 90.2567 206.57 104.612 196.176 115ZM115 114.975C114.992 114.984 114.984 114.992 114.975 115L115 115.025C115.008 115.016 115.016 115.008 115.025 115C115.016 114.992 115.008 114.984 115 114.975Z",
        "trapezoid" : "M192.373 230H38V0L192.373 77.9661V77.7088V230Z",
      },
      hourglass : "M22 174.096L68.2373 115.027L22 57.0137L68.2373 -1H161.763L208 58.0685L161.763 115.027L208 174.096L160.712 230H65.0847L22 174.096Z",
        bubble : "M115 190.42C103.735 197.786 90.6648 202 76.7267 202C34.3517 202 0 163.049 0 115C0 66.9512 34.3517 28 76.7267 28C90.6648 28 103.735 32.2141 115 39.5801C126.265 32.2141 139.335 28 153.273 28C195.648 28 230 66.9512 230 115C230 163.049 195.648 202 153.273 202C139.335 202 126.265 197.786 115 190.42Z",
        arc : "M25 89.6207C25 40.1245 65.2944 0 115 0C164.706 0 205 40.1245 205 89.6207V230H25V89.6207Z",
        circle : "M229 115C229 178.513 177.513 230 114 230C50.4873 230 -1 178.513 -1 115C-1 51.4873 50.4873 0 114 0C177.513 0 229 51.4873 229 115Z",
        curve : "M230 0C102.975 0 0 102.975 0 230H148.432C148.432 184.948 184.948 148.432 230 148.432V0Z ",
        ellipse : "M0 115C0 79.1015 29.2182 50 65.2607 50H164.739C200.782 50 230 79.1015 230 115C230 150.899 200.782 180 164.739 180H65.2608C29.2182 180 0 150.899 0 115Z",
        flag : "M0 135.408C0 135.408 65.7153 133.417 74.6781 69.6947C83.641 5.9726 151.34 0 151.34 0L230 97.574C230 97.574 168.267 90.6024 154.33 154.325C140.393 218.047 111.514 227.009 79.6519 229L0 135.408Z",
        parallelepiped : "M74.0824 3H230L155.918 226.109H0L74.0824 3Z",
        polygon : "M18 56.0697L44.687 227.711L155.883 230L212.222 138.458L188.5 0H130.679L18 56.0697Z",
        polyhedron : "M226.542 139.94C228.688 138.694 230.006 136.398 230 133.917L229.911 97.532C229.905 95.0507 228.576 92.7611 226.424 91.5257L195.549 73.8025C193.398 72.5673 192.068 70.2779 192.062 67.7968L191.974 33.0404C191.968 30.5594 190.638 28.2701 188.487 27.0348L156.934 8.92202C154.782 7.68681 152.135 7.69329 149.989 8.93902L116.297 28.5C115.934 28.7108 115.521 28.8219 115.101 28.8219C114.685 28.8219 114.277 28.7129 113.916 28.5058L79.8039 8.922C77.6523 7.68679 75.0052 7.69309 72.8596 8.93851L41.3895 27.205C39.2431 28.4509 37.9246 30.7474 37.9309 33.2292L38.0186 67.6157C38.0249 70.0973 36.7067 72.3937 34.5605 73.6396L3.4581 91.6963C1.3122 92.9421 -0.00602218 95.2381 2.06869e-05 97.7194L0.0886313 134.104C0.0946742 136.586 1.42409 138.875 3.57605 140.111L38.4579 160.135C38.5494 160.187 38.6619 160.187 38.7534 160.135C38.9511 160.021 39.1975 160.164 39.1981 160.392L39.288 197.327C39.294 199.808 40.6234 202.098 42.7754 203.333L74.3278 221.446C76.4795 222.681 79.1267 222.674 81.2723 221.429L116.09 201.215C116.161 201.174 116.204 201.099 116.204 201.017V200.9C116.204 200.839 116.254 200.789 116.316 200.789C116.378 200.789 116.428 200.839 116.428 200.9V201.136C116.428 201.26 116.494 201.375 116.602 201.437L151.465 221.446C153.617 222.681 156.264 222.674 158.409 221.429L189.873 203.162C192.019 201.917 193.337 199.621 193.331 197.139L193.25 163.285C193.244 160.803 194.562 158.507 196.708 157.262L226.542 139.94Z",
        quadrefoil : "M196.176 115C206.57 125.388 213 139.743 213 155.6C213 187.301 187.301 213 155.6 213C139.743 213 125.388 206.57 115 196.175C104.612 206.57 90.2566 213 74.3997 213C42.6987 213 17 187.301 17 155.6C17 139.743 23.4296 125.388 33.824 115C23.4296 104.612 17 90.2567 17 74.4C17 42.6989 42.6987 17 74.3997 17C90.2566 17 104.612 23.4299 115 33.8246C125.388 23.4299 139.743 17 155.6 17C187.301 17 213 42.6989 213 74.4C213 90.2567 206.57 104.612 196.176 115ZM115 114.975C114.992 114.984 114.984 114.992 114.975 115L115 115.025C115.008 115.016 115.016 115.008 115.025 115C115.016 114.992 115.008 114.984 115 114.975Z",
        trapezoid : "M192.373 230H38V0L192.373 77.9661V77.7088V230Z",
    }
  },
  computed: {
    // Num(){
    //     var n = this.pos
    // while(this.$store.homeContent.data.work.length - 1 > n){
    //     n = n - this.$store.homeContent.data.work.length - 1
    // } 
    //     return
    // },
    selectImg() {
      return this.$store.state.homeContent.home.data.work[this.realNum].gif.url
    },
    title() {
      const objPr = this.$store.state.prj
      const key = this.$store.state.homeContent.home.data.work[this.realNum].prj.uid
      var result = objPr.find(obj => {
        return obj.uid === key
      })
      return result.data.title
    },
    url() {
      return this.$store.state.homeContent.home.data.work[this.realNum].prj.uid
    },
    shape() {
      var k = this.$store.state.homeContent.home.data.work[this.realNum].shape.toLowerCase();
      // console.log(this.clip[k])
      console.log(k)
      return k
    }
  },
  methods: {
    changeTitle(text) {
      this.$store.commit("pushTitle", text)
      if (text) {
        this.$store.commit("pushImg", this.selectImg)
        // this.$store.commit("spreadImg")
      } else {
        this.$store.commit("removeImg")
      }
    },
    selectI(m){
      this.imma = this.$store.state.homeContent.home.data.work[m].gif.url
    }
  },
  
  mounted() {
       console.log(this.dataPrj) 
    if (this.$store.state.homeContent.home.data.work.length - 1 > this.pos) this.realNum = this.pos
    else {
      var m = this.pos
      while (this.$store.state.homeContent.home.data.work.length <= m) {
        m = m - this.$store.state.homeContent.home.data.work.length
      }
      this.realNum = m
      this.selectI(m)
    }

  },

}
</script>

<style lang="scss" scoped>
.column__item-img {
  // pointer-events: none;
  width: 100%;
  height: 100%;
}

.mask {
  // margin: 0;
  // -webkit-mask-image: url("../assets/images/mask/arc.svg");
  // mask-image: url("../assets/images/mask/arc.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  user-select: none;
  pointer-events: none;
  overflow: hidden;

  // pointer-events: none;
  a {
    pointer-events: visible;
  }
}

.hover-path {
  fill: transparent;
  opacity: 1;
}


#grayscalePhoto,
.hover-photo {
  pointer-events: none;
}

a .hover-photo {
  opacity: 0;
  transition: opacity 400ms ease-in-out 100ms;
}

a:hover .hover-photo {
  opacity: 1;
  transition-delay: 0;
}
</style>