<template>
    <router-link :to="`/${page}`" class="listProject__container">
        <!-- <img class="listProject__preview" :src="image" alt=""> -->
        <div class="listProject__title">{{ title }}</div><div class="listProject__year">{{ year }}</div>
    </router-link>
</template>

<script>
    export default {
        props:{
            title: String,
            year: Number,
            page: String,
        }
    }
</script>

<style lang="scss" scoped>
    .listProject{
        &__container{
            display:flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 16px;
            position: relative;
            margin-bottom: 32px;
            transition: all 300ms ease-out;
            &:hover{
                .listProject__title{
                    text-decoration: underline
                }
                .listProject__year{
                    @include main-color-p3;
                    background: black;
                }
            }
        }
        &__title{
         font-family: Roslindale, serif;
        font-weight: normal;
        font-variation-settings: "wght" 380, "wdth" 75;
        font-size: 60px;
        line-height: 120%;
        // width: max-content;

        // max-width: calc(100% - 56px);
        @media screen and (max-width:768px) {
            font-size: 42px;
        }
        }
        &__year{
            font-family: Roobert, Arial, Helvetica, sans-serif;
            font-size: 14px;
            border: 1px solid black;
            border-radius: 40px;
            padding: 4px 10px;
            margin-top: 10px;
            @media screen and (max-width:768px) {
            font-size: 12px;
            margin-top: 2px;
            padding: 3px 6px;
        }
        }

        &__preview{
            position: absolute;
            width: 50px;
            height: 50px;
            top: 0;
            left: 20px;
        }
    }
</style>